<template>
    <section class="py-6 px-lg-6" :style="{backgroundColor:bgColor}">
        <slot></slot>
    </section>
</template>
<script>

export default {
    props: {
        bgColor: {
            type: String,
            default: 'transparent'
        }
    }
};
</script>

<style scoped>

</style>
