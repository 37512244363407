<template>
    <section class="service-listing" :style="{backgroundColor:bgColor}">
        <h2 class="font-weight-bold mb-4">
            <slot name="header"></slot>
        </h2>
        <div class="text-body fl-x fl-j-c">
            <slot name="body"></slot>
        </div>
        <footer>
            <slot name="footer">
            </slot>
        </footer>
    </section>
</template>
<script>
export default {
    props: {
        bgColor: {
            type: String,
            default: 'transparent'
        }
    }
};
</script>
<style scoped lang="scss">
section {
    text-align: center;
}

/*.header{*/
/*    padding-top: 2.5rem;*/
/*    padding-bottom: 2rem;*/
/*    text-align: center;*/
/*}*/
/*.header{*/
/*    font-family: 'poppins-semibold';*/
/*    font-size: 30px;*/
/*}*/

// footer a.router-link-active {
//     text-decoration: none;
// }

// a {
//     display: inline-block;
//     line-height: unset;
//     border: 1px solid var(--color-primary);
//     border-radius: 0.3rem;
//     padding-left: 2.5rem;
//     padding-right: 2.5rem;
//     padding-top: 0.5rem;
//     padding-bottom: 0.8rem;
//     transition: all 300ms ease-in-out;
//     margin-bottom: 1rem;
// }

a:hover {
    background: var(--color-primary);
    color: #fafafa;
}

footer a:not(:last-child) {
    margin-right: 1rem;
}

footer {
    margin-top: 1.5rem;
    padding-bottom: 2rem;
}

//footer:after {
//    position: absolute;
//    content: '';
//    border-bottom: 1px solid #d2d7da;
//    width: 70%;
//    transform: translateX(-50%);
//    bottom: -15px;
//    left: 50%;
//}

.service-listing:not(:last-child) {
    margin-bottom: 1rem;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        height: 1px;
        width: 80%;
        background: var(--color-primary);
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }
}
</style>
